//Common

export const GetCompanyList = {
  url: "employer/user/employers",
  method: "GET",
};

export const GetCountryList = {
  url: "employer/job/country",
  method: "GET",
};

export const GetStateList = {
  url: "employer/job/state",
  method: "GET",
};

export const GetCityList = {
  url: "employer/job/city",
  method: "GET",
};

export const GetCandidateFilterList = {
  url: "employer/candidate/filterfields",
  method: "GET",
};

export const GetApplicantFilterList = {
  url: "employer/applicant/filterfields",
  method: "GET",
};
