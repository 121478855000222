//Login

export const EmployerLogin = {
  url: "employer/login",
  method: "POST",
};

export const ProfileUpdate = {
  url: "employer/profile/update",
  method: "PUT",
};

export const ForgotPasswordApi = {
  url: "employer/profile/forgot-password",
  method: "POST",
};

export const ResetPasswordApi = {
  url: "employer/profile/reset-password",
  method: "POST",
};

export const EmployerSignup = {
  url: "employer/signup",
  method: "POST",
};
