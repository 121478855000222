import React, { useState, useEffect } from "react";
import { Badge } from "@material-ui/core";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "underscore";
import { clone, sortBy, isEmpty, isNull } from "underscore";

import { ActCreators } from "../../redux/bindActionCreator";
import CustomDropdown from "../../components/CustomDropdown/Cutsom-Dropdown";
import NotificationDialog from "../../components/Notification/NotificationDialog/NotificationDialog";
import { GetCompanyList } from "../../common/module_wise_api_url/common";
import { commonApiFN } from "../../common/api";
import {GetNotificationList, GetUnreadNotificationCount} from "../../common/module_wise_api_url/notification";

const Header = (props) => {
  const selectedCompany = _.get(props, ["globalState", "selectedCompany"], {});
  const refreshCompanyCount = _.get(
    props,
    ["globalState", "refreshCompanyCount"],
    0
  );
  const unread_Notifications_Count = _.get(
    props,
    ["globalState", "NotificationsCount"],
    0
  );
  const isAdmin = _.get(props, ["globalState", "isAdmin"], false);
  const user = _.get(props, ["globalState", "user"], {});

  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [pathName, setPathName] = useState("/dashboard");

  useEffect(() => {
    setPathName(window.location.pathname);

    const unlisten = props.history.listen((location, action) => {
      setPathName(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, []);

  const notificationDialog = (e) => {
    getUnreadNotificationList();
    setNotificationDialogOpen(!notificationDialogOpen);
  };

  const getUnreadNotificationList = async () => {
    const requestData = _.clone(GetNotificationList);
    const response = await commonApiFN(requestData);
    if (response.success) {
      setUnreadNotifications(response.data.unread_notifications);
    }
  };

  const getUnreadNotificationCount = async () => {
    const requestData = _.clone(GetUnreadNotificationCount);
    const response = await commonApiFN(requestData);
    if (response.success) {
      props.refreshNotificationsCount(response.data.unread_notification_count);
    }
  };

  useEffect(() => {
    getUnreadNotificationList();
    getUnreadNotificationCount();
  }, []);

  let role = "";
  if (localStorage.getItem("role")) {
    role = localStorage.getItem("role");
  }
  let [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    if (role === "admin") getCompanyListFn();
    else
      props.updateSelectedCompany({
        id: user.employer_id,
        //name: user.employer_name,
      });
  }, []);

  useEffect(() => {
    if (isAdmin && refreshCompanyCount > 0) getCompanyListFn();
  }, [refreshCompanyCount]);

  const getCompanyListFn = async () => {
    const requestData = clone(GetCompanyList);
    const response = await commonApiFN(requestData);

    if (response && response.data && response.data.employer_list) {
      setCompanyList(
        sortBy(response.data.employer_list, ({ name = "" }) => {
          return name !== null ? name.toLowerCase() : "";
        })
      );

      if (selectedCompany !== {} && !_.isEmpty(selectedCompany)) {
        const isExists = _.findWhere(response.data.employer_list, {
          id: selectedCompany.id,
        });

        props.updateSelectedCompany(isExists ? isExists : {});
      }
    } else {
      setCompanyList([]);
    }
  };

  return (
    <>
      <header className="header">
        <div className="HeaderLeft">
          {isAdmin ? (
            <div className="" style={{ zIndex: 2000 }}>
              <CustomDropdown
                name="selectcompany"
                data={companyList}
                displayValue="id"
                displayLabel="name"
                isMulti={false}
                placeholder="All Employers"
                onClick={(value) => {
                  props.updateSelectedCompany(value);
                }}
                value={selectedCompany}
                noSelectionText="All Employers"
              />
            </div>
          ) : (
            <>
              {user.employer_logo &&
                user.employer_logo.url &&
                !isNull(user.employer_logo.url) &&
                !isEmpty(user.employer_logo.url) && (
                  <div className="university-logo">
                    <img
                      src={user.employer_logo.url}
                      alt="Employer logo"
                    />
                  </div>
                )}
            </>
          )}
        </div>
        <div className="HeaderRight">
          <Link
            to="/help-center"
            className={`Question-Icon ${
              pathName.includes("/help-center") ? "active" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.156"
              height="19.829"
              viewBox="0 0 11.156 19.829"
            >
              <path
                id="Question"
                d="M5.585-14.546A5.67,5.67,0,0,1,5.26-12.48a5.279,5.279,0,0,1-.84,1.446A10.468,10.468,0,0,1,3.338-9.907,10.468,10.468,0,0,0,2.255-8.779a5.279,5.279,0,0,0-.84,1.446A5.67,5.67,0,0,0,1.09-5.268v.151H-1.393v-.151a7.922,7.922,0,0,1,.325-2.445,6.047,6.047,0,0,1,.84-1.7,7.527,7.527,0,0,1,1.09-1.211q.575-.507,1.082-1.052a4.974,4.974,0,0,0,.833-1.211A3.781,3.781,0,0,0,3.1-14.546a2.919,2.919,0,0,0-.817-2.134,2.839,2.839,0,0,0-2.119-.833,3.079,3.079,0,0,0-2.384.946,4.216,4.216,0,0,0-.87,2.929v.3H-5.57v-.3a6.013,6.013,0,0,1,1.582-4.48A5.663,5.663,0,0,1,.167-19.678a5.912,5.912,0,0,1,2.149.386,5.18,5.18,0,0,1,1.718,1.067A4.993,4.993,0,0,1,5.169-16.6,5.02,5.02,0,0,1,5.585-14.546ZM-1.7-1.393a1.55,1.55,0,0,1,.416-1.135A1.509,1.509,0,0,1-.151-2.952a1.509,1.509,0,0,1,1.128.424,1.55,1.55,0,0,1,.416,1.135A1.524,1.524,0,0,1,.976-.265,1.524,1.524,0,0,1-.151.151,1.524,1.524,0,0,1-1.279-.265,1.524,1.524,0,0,1-1.7-1.393Z"
                transform="translate(5.57 19.678)"
                fill="#607790"
              />
            </svg>
          </Link>
          <Link
            to="#"
            onClick={(e) => notificationDialog(e)}
            aria-label={"Notifications"}
            className={`Bell-Icon ${
              pathName.includes("notification") ? "active" : ""
            }`}
          >
            {unread_Notifications_Count &&
            unread_Notifications_Count !== null &&
            unread_Notifications_Count > 0 ? (
              <Badge
                badgeContent={unread_Notifications_Count}
                color="secondary"
                className="notifi-boxs"
              >
                <svg
                  width="19px"
                  height="21px"
                  viewBox="0 0 19 21"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Shape</title>
                  <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Icon/TopBar/Notifications/Deselected"
                      transform="translate(-11.000000, -10.000000)"
                      fillRule="nonzero"
                      stroke="#607790"
                      strokeWidth="2"
                    >
                      <path
                        d="M21,30 C22.65,30 24,29.1 24,28 L18,28 C18,29.1 19.335,30 21,30 Z M26.875,22.9005259 L26.875,20.9655172 L26.875,20.9655172 C26.875,17.3662069 25.1325,14.3531034 22.09375,13.5558621 L22.09375,12.7586207 C22.09375,11.7855172 21.381875,11 20.5,11 C19.618125,11 18.90625,11.7855172 18.90625,12.7586207 L18.90625,13.5558621 C15.856875,14.3531034 14.125,17.3544828 14.125,20.9655172 L14.125,22.9005259 C14.125,23.9187877 13.7462826,24.9006537 13.0625,25.6551724 L12.3936493,26.3932145 C12.1403123,26.6727588 12,27.0365337 12,27.4137931 C12,27.7375462 12.2624538,28 12.5862069,28 L28.4137931,28 C28.7375462,28 29,27.7375462 29,27.4137931 C29,27.0365337 28.8596877,26.6727588 28.6063507,26.3932145 L27.9375,25.6551724 C27.2537174,24.9006537 26.875,23.9187877 26.875,22.9005259 Z"
                        id="Shape"
                      ></path>
                    </g>
                  </g>
                </svg>
              </Badge>
            ) : (
              <svg
                width="19px"
                height="21px"
                viewBox="0 0 19 21"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Shape</title>
                <g
                  id="Symbols"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Icon/TopBar/Notifications/Deselected"
                    transform="translate(-11.000000, -10.000000)"
                    fillRule="nonzero"
                    stroke="#607790"
                    strokeWidth="2"
                  >
                    <path
                      d="M21,30 C22.65,30 24,29.1 24,28 L18,28 C18,29.1 19.335,30 21,30 Z M26.875,22.9005259 L26.875,20.9655172 L26.875,20.9655172 C26.875,17.3662069 25.1325,14.3531034 22.09375,13.5558621 L22.09375,12.7586207 C22.09375,11.7855172 21.381875,11 20.5,11 C19.618125,11 18.90625,11.7855172 18.90625,12.7586207 L18.90625,13.5558621 C15.856875,14.3531034 14.125,17.3544828 14.125,20.9655172 L14.125,22.9005259 C14.125,23.9187877 13.7462826,24.9006537 13.0625,25.6551724 L12.3936493,26.3932145 C12.1403123,26.6727588 12,27.0365337 12,27.4137931 C12,27.7375462 12.2624538,28 12.5862069,28 L28.4137931,28 C28.7375462,28 29,27.7375462 29,27.4137931 C29,27.0365337 28.8596877,26.6727588 28.6063507,26.3932145 L27.9375,25.6551724 C27.2537174,24.9006537 26.875,23.9187877 26.875,22.9005259 Z"
                      id="Shape"
                    ></path>
                  </g>
                </g>
              </svg>
            )}
          </Link>
          <Link
            to="/my-profile"
            className={`User-Icon ${
              pathName.includes("my-profile") ? "active" : ""
            }`}
          >
            <svg
              width="19px"
              height="20px"
              viewBox="0 0 19 20"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Group</title>
              <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g
                  id="Icon/TopBar/Profile/Deelected"
                  transform="translate(-11.000000, -10.000000)"
                  stroke="#607790"
                  strokeWidth="2"
                >
                  <g id="Group" transform="translate(12.000000, 11.000000)">
                    <path
                      d="M10.5678383,8.16 C14.2583721,9.06404209 16.9911621,12.32694 16.9911621,16.2138077 C16.9911621,16.2138077 12.6753772,17.8036364 8.12164074,17.8036364 C3.56790426,17.8036364 -3.01980663e-13,16.2138077 -3.01980663e-13,16.2138077 C-3.01980663e-13,12.6556623 2.29045569,9.62016969 5.50919676,8.43895646"
                      id="Stroke-3"
                    ></path>
                    <path
                      d="M12.558685,4.45079749 C12.558685,6.90922695 10.5741729,8.90181818 8.12620795,8.90181818 C5.67824296,8.90181818 3.69373089,6.90922695 3.69373089,4.45079749 C3.69373089,1.99259123 5.67824296,4.52970994e-14 8.12620795,4.52970994e-14 C10.5741729,4.52970994e-14 12.558685,1.99259123 12.558685,4.45079749 Z"
                      id="Stroke-5"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </Link>
        </div>
      </header>
      {notificationDialogOpen && (
        <div className="row">
          <NotificationDialog
            isOpen={notificationDialogOpen}
            handleClose={notificationDialog}
            title="New Notifications"
            notifications={unreadNotifications}
            isNew={true}
            history={props.history}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalState: { ...state.loginReducer },
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
