//example
export function setUserData(res) {
  return { type: "SET_USER_DATA", res };
}

export function updateUserData(res) {
  return { type: "UPDATE_USER_DATA", res };
}

export function resetUserData() {
  return { type: "RESET_USER_DATA" };
}

export function updateSelectedCompany(res) {
  return { type: "UPDATE_SELECTED_COMPANY", res };
}

export function refreshCompanyList() {
  return { type: "REFRESH_COMPANY_LIST" };
}

export function refreshNotificationsCount(res) {
  return { type: "NOTIFICATION_COUNT", res };
}
