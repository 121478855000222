import React from "react";
import CardCustomStyles from "./CardCustomStyles";
import { Card, CardHeader, CardContent } from "@material-ui/core";

const CardCustom = ({
  children,
  action,
  title,
  subheader,
  avatar,
  className,
  borderRadius = 10,
  onClick = () => {},
}) => {
  return (
    <CardCustomStyles borderRadius={borderRadius} className="card-wrapper">
      <Card className={className} onClick={onClick}>
        {title && (
          <CardHeader
            avatar={avatar}
            title={title}
            subheader={subheader}
            action={action}
          />
        )}
        {children && (
          <CardContent className="card-content">{children}</CardContent>
        )}
      </Card>
    </CardCustomStyles>
  );
};

export default CardCustom;
