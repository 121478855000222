import React, { useEffect } from "react";
import "./App.css";
import "./responsive.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TagManager from "react-gtm-module";

import Layout from "./pages/Layout/layout";
import Login from "./pages/Login/login";
import ForgetPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import Signup from "./pages/Signup/Signup";

import "./styles/main.scss";
import "./index.scss";
import config from "./config";

function App() {
  // Google tag manager script
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: config.googleTagManagerID(),
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    // Microsoft clarity script by Dhaval
    if (process.env.REACT_APP_ENV === "prod")
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "mubkvmfhx8");
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/forget-password" exact component={ForgetPassword} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/" component={Layout} />
        </Switch>
      </Router>

      <ToastContainer />
    </div>
  );
}

export default App;
