import React, { useEffect, useState, useRef } from "react";

import Chip from "@material-ui/core/Chip";
import CheckBox from "../Checkbox/Checkbox";
import _ from "underscore";
import { Scrollbars } from "react-custom-scrollbars";
import Textbox from "../Textbox/Textbox";
import DropArrow from "../../assets/svg/chevArrowDown.svg";

const CustomDropdown = ({
  data,
  displayLabel,
  displayValue,
  placeholder,
  noOption = "No options to display",
  noSelectionText = "Select any",
  selectAllText = "All options selected",
  error = false,
  disabled = false,
  readonly=false,
  errorText,
  onBlur = () => {},
  className,
  name = "dropdown-textbox",
  value,
  isMulti = false,
  ...props
}) => {
  let [getvalue, setValue] = useState(value ? value : []);
  const [open, setOpen] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [text, setText] = useState("");
  const handleDelete = (index, val) => {
    const list = getvalue;

    list.splice(index, 1);
    setValue([...list]);

    props.onClick(list);
    const request = listingData;
    const existIndex = _.findIndex(request, {
      [displayValue]: val[displayValue],
    });
    if (existIndex >= 0) {
      request[existIndex]["checked"] = false;
    }

    const totalCheckedData = _.where(request, { checked: true });

    if (totalCheckedData.length === listingData.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }

    setListingData([...request]);
  };

  const closeDropdown = (e) => {
    if (open === true) {
      setOpen(false);
    }
  };

  const isInitialOpen = useRef(true);
  useEffect(() => {
    if (isInitialOpen.current) {
      isInitialOpen.current = false;
    } else {
      if (!open) {
        onBlur();
        setText("");
        setShortListData("");
      }
    }

    window.addEventListener("click", closeDropdown);
    return () => window.removeEventListener("click", closeDropdown);
  }, [open]);

  useEffect(() => {
    if (isMulti && value && (_.isEmpty(value) || _.isUndefined(value)) && data && data.length > 0) {
      setListingData([
        ..._.each(data, function (l_data) {
          l_data.checked = false;
        }),
      ]);
    } else {
      setListingData(data);
    }

    setMainData(data);
  }, []);

  useEffect(() => {
    setListingData(data);
    setMainData(data);
  }, [data]);

  useEffect(() => {
    if (value && (!_.isEmpty(value) || !_.isUndefined(value)) && props.UpdateCheck) {
      const unCheckedData = _.where(value, { checked: false });
      if (unCheckedData.length === value.length) {
        setValue([]);

        if (value && value.length > 0 && isMulti && listingData && listingData.length) {
          const request = listingData;
          _.each(value, function (dat) {
            const existindex = _.findIndex(request, {
              [displayValue]: dat[displayValue],
            });
            if (existindex >= 0) {
              request[existindex]["checked"] = false;
            }
          });

          setListingData([...request]);
        }
      } else {
        setValue(value);

        if (value && value.length > 0 && isMulti && listingData && listingData.length) {
          const request = listingData;
          _.each(value, function (dat) {
            const existindex = _.findIndex(request, {
              [displayValue]: dat[displayValue],
            });
            if (existindex >= 0) {
              request[existindex]["checked"] = true;
            }
          });

          setListingData([...request]);
        }
      }
    }
  }, [props.UpdateCheck]);

  useEffect(() => {
    if (value && (!_.isEmpty(value) || !_.isUndefined(value))) {
      const unCheckedData = _.where(value, { checked: false });
      if (unCheckedData.length === value.length) {
        setValue([]);
        if (value && value.length > 0 && isMulti && listingData && listingData.length) {
          const request = listingData;
          _.each(value, function (dat) {
            const existindex = _.findIndex(request, {
              [displayValue]: dat[displayValue],
            });
            if (existindex >= 0) {
              request[existindex]["checked"] = false;
            }
          });

          setListingData([...request]);
        }
      } else {
        setValue(value);
        if (value && value.length > 0 && isMulti && listingData && listingData.length) {
          const request = listingData;
          _.each(value, function (dat) {
            const existindex = _.findIndex(request, {
              [displayValue]: dat[displayValue],
            });
            if (existindex >= 0) {
              request[existindex]["checked"] = true;
            }
          });

          setListingData([...request]);
        }
      }
    }
  }, [value]);

  const selectedValues = (e, val, index) => {
    const request = listingData;
    request[index]["checked"] = !request[index]["checked"];

    const totalCheckedData = _.where(request, { checked: true });

    if (totalCheckedData.length === listingData.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }

    setListingData([...request]);

    const getvalueData = getvalue;
    if (request[index]["checked"] === true) {
      getvalueData.push(val);
    } else {
      const existIndex = _.findIndex(getvalueData, {
        [displayValue]: val[displayValue],
      });
      getvalueData.splice(existIndex, 1);
    }

    setValue([...getvalueData]);

    props.onClick(getvalue);
  };

  const SelectAll = () => {
    const setData = [];

    setAllChecked(!allChecked);

    _.each(listingData, function (l_data) {
      l_data.checked = !allChecked;
      setData.push(l_data);
    });

    if (!allChecked) {
      setValue([...setData]);
      props.onClick(setData);
    } else {
      //getvalue=[];
      setValue([]);
      props.onClick([]);
    }

    setListingData([...listingData]);
  };

  const setShortListData = (searchParams = "") => {
    const reg = new RegExp(searchParams.toLowerCase());
    const existData = _.filter(mainData, (l_data = {}) => {
      const res = (
        l_data[displayLabel] && l_data[displayLabel] !== null ? l_data[displayLabel] : ""
      )
        .toString()
        .toLowerCase()
        .match(reg);
      if (res && res.length) {
        return l_data;
      }
    });

    setListingData(existData);

    if (getvalue && getvalue.length == existData.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  const demoIcon = (
    <svg
      width="7px"
      height="7px"
      viewBox="0 0 7 7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>Group</title>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="square"
      >
        <g id="Group" transform="translate(1.000000, 1.000000)" stroke="#000485">
          <line x1="0.125" y1="0.119047619" x2="4.875" y2="4.86819048" id="Line"></line>
          <line
            x1="0.125"
            y1="0.119047619"
            x2="4.875"
            y2="4.86819048"
            id="Line"
            transform="translate(2.500000, 2.500000) scale(-1, 1) translate(-2.500000, -2.500000) "
          ></line>
        </g>
      </g>
    </svg>
  );

  const closeOpenDropdowns = (event) => {
    if (name !== "selectstatusmultiple") {
      let opendds = document.querySelectorAll(".dropdown-wrapper");

      opendds.forEach(function (menus) {
        menus.click();
      });
    } else {
      let opendds = document.querySelectorAll(".multipleSelect .dropdown-wrapper");

      opendds.forEach(function (menus) {
        menus.click();
      });
    }
  };

  const multiSelectOption = (val, index) => {
    const getOptionFromValue = _.isEmpty(getvalue)
      ? false
      : _.find(getvalue, { [displayValue]: val[displayValue] }, {});
    const isSelected =
      (getOptionFromValue !== false &&
        !_.isEmpty(getOptionFromValue) &&
        getOptionFromValue.checked) ||
      val.checked;

    return (
      <li
        key={index}
        className={isSelected ? "active" : ""}
        style={{
          cursor: "pointer",
        }}
      >
        <CheckBox
          onClick={(e) => selectedValues(e, val, index)}
          value={val[displayValue]}
          isChecked={isSelected ? true : false}
        />
        {val[displayLabel]}
      </li>
    );
  };

  return (
    <>
      {isMulti ? (
        <div
          className={open ? "dropdown-wrapper multili open" : "dropdown-wrapper"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="custom-dropdowninput">
            <Textbox
              name={name}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                setShortListData(e.target.value);
              }}
              onClick={(e) => {
                closeOpenDropdowns();
                e.preventDefault();
                e.stopPropagation();
                setOpen(!open);
              }}
              placeholder={placeholder}
              error={error}
              errorText={errorText}
              // onBlur={onBlur}
              inputProps={{
                autoComplete: "off",
              }}
            />
            <span onClick={() => setOpen(!open)}>
              <img src={DropArrow} />
            </span>
          </div>
          <div className="customscroll-div custom-dropdownul">
            <ul className={listingData.length > 0 ? "select-all-option" : "no-option"}>
              {listingData.length > 0 ? (
                <li
                  className={allChecked ? "active" : ""}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <CheckBox
                    value={
                      allChecked || (getvalue.length > 0 && getvalue.length === listingData.length)
                    }
                    isChecked={
                      allChecked || (getvalue.length > 0 && getvalue.length === listingData.length)
                    }
                    onClick={() => SelectAll()}
                  />
                  Select all
                </li>
              ) : (
                <li>No options to display</li>
              )}
            </ul>

            {listingData.length <= 10 ? (
              <div>
                <ul>{listingData.map((val, index) => multiSelectOption(val, index))}</ul>
              </div>
            ) : (
              <Scrollbars style={{ minHeight: 300, maxHeight: 300 }}>
                <div>
                  <ul>{listingData.map((val, index) => multiSelectOption(val, index))}</ul>
                </div>
              </Scrollbars>
            )}
          </div>
          <div className="dropdown-array-option">
            {getvalue.length > 0 && getvalue.length === mainData.length ? (
              <Chip
                // icon={icon}
                label={selectAllText}
                // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                onDelete={() => SelectAll()}
                color="primary"
                variant="outlined"
                deleteIcon={demoIcon}
              />
            ) : (
              <>
                {getvalue &&
                  getvalue.length > 0 &&
                  getvalue.map((values, index) => (
                    <Chip
                      key={index}
                      // icon={icon}
                      label={values[displayLabel]}
                      // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                      onDelete={() => handleDelete(index, values)}
                      color="primary"
                      variant="outlined"
                      deleteIcon={demoIcon}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className={open ? "dropdown-wrapper open" : "dropdown-wrapper"}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <div className={`custom-dropdowninput ${className}`}>
            <Textbox
              name={name}
              value={getvalue ? getvalue[displayLabel] : text ? text : ""}
              onClick={(e) => {
                closeOpenDropdowns();
                e.preventDefault();
                e.stopPropagation();
                setOpen(!open);
              }}
              onChange={(e) => {
                if (getvalue) {
                  setValue();
                  props.onClick({});
                }
                setText(e.target.value);
                setShortListData(e.target.value);
              }}
              placeholder={placeholder}
              error={error}
              errorText={errorText}
              // onBlur={onBlur}
              inputProps={{
                autoComplete: "off",
              }}
              disabled={disabled}
              readonly={readonly}
            />
            <span onClick={() => setOpen(!open)}>
              <img src={DropArrow} alt="" />
            </span>
          </div>
          <div className="customscroll-div custom-dropdownul">
            {listingData.length <= 10 ? (
              <div>
                <ul>
                  {listingData.length == 0 ? (
                    <li
                      className={!getvalue ? "active" : ""}
                      onClick={() => {
                        setText("");
                        setShortListData("");
                        setValue();
                        props.onClick({});
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {noOption}
                    </li>
                  ) : (
                    <li
                      className={!getvalue ? "active" : ""}
                      onClick={() => {
                        setText("");
                        setShortListData("");
                        setValue();
                        props.onClick({});
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {noSelectionText}
                    </li>
                  )}

                  {listingData &&
                    listingData.length > 0 &&
                    listingData.map((val, index) => (
                      <li
                        key={index}
                        className={
                          getvalue && getvalue[displayLabel] == val[displayLabel] ? "active" : ""
                        }
                        onClick={(e) => {
                          setValue(val);
                          props.onClick(val);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {val[displayLabel]}
                      </li>
                    ))}
                </ul>
              </div>
            ) : (
              <Scrollbars style={{ minHeight: 300, maxHeight: 300 }}>
                <div>
                  <ul>
                    <li
                      className={!getvalue ? "active" : ""}
                      onClick={() => {
                        setValue();
                        props.onClick({});
                        setOpen(!open);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {noSelectionText}
                    </li>
                    {listingData &&
                      listingData.length > 0 &&
                      listingData.map((val, index) => (
                        <li
                          key={index}
                          className={
                            getvalue && getvalue[displayLabel] == val[displayLabel] ? "active" : ""
                          }
                          onClick={(e) => {
                            setValue(val);
                            props.onClick(val);
                            setOpen(!open);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {val[displayLabel]}
                        </li>
                      ))}
                  </ul>
                </div>
              </Scrollbars>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomDropdown;
