import { isEmpty, isNull, map } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { InfoPanel } from "../../InfoPanel/InfoPanel";
import NotificationDialogStyles from "./NotificationDialogStyles";

import UserIcon from "../../../assets/svg/NAdmins.svg";
import settingsIcon from "../../../assets/svg/NSettings.svg";
import ApplicantIcon from "../../../assets/svg/NStudent-Services.svg";
import JobIcon from "../../../assets/svg/NTopics.svg";
import NoNotifications from "../../../assets/svg/noNotifications.svg";

const NotificationDialog = ({
  handleClose,
  isOpen,
  title,
  notifications,
  handleDelete,
  isFetching,
  noTitle,
  isNew,
  readApiCall,
  ...props
}) => {
  const [redirect, setRedirect] = useState(false);

  const getNotificationDetailsByType = (notification) => {
    const { type, title } = notification;

    if (type === "job") {
      if (title === "Job close") {
        return {
          icon: JobIcon,
          redirectURL: "/jobs?tab=Closed",
        };
      } else {
        return {
          icon: JobIcon,
          redirectURL: "/jobs?tab=Open",
        };
      }
    } else if (type === "job_application") {
      return {
        icon: ApplicantIcon,
        redirectURL: "/applicants",
      };
    } else if (type === "employer_signup") {
      return {
        icon: ApplicantIcon,
        redirectURL: "/employers?tab=Requests",
      };
    } else {
      return {
        icon: UserIcon,
      };
    }
  };

  useEffect(() => {
    if (redirect === true) {
      handleClose();
    }
  }, [redirect]);

  return (
    <NotificationDialogStyles>
    <span
      className={`notification-overlay ${
        isOpen ? "notification-overlay-open" : "d-none"
      }`}
      onClick={(e) => {
        e.preventDefault();
        handleClose();
      }}
    ></span>
    <div
      className={`notifications-drawer ${
        isOpen ? "notifications-drawer-open" : ""
      }`}
    >
      <div className="notification-dialog-header mb-15">
        <div className="notification-dialog-title-view-all-wrapper">
          <h2 className="header-primary">New notifications</h2>
        </div>
        <div className="notification-dialog-setting-close-icon-wrapper">
          <Link to="/notification-settings"
          onClick={() => handleClose()}
          className={isOpen ? "notification-setting" : "d-none"}
          aria-hidden={isOpen ? false : true}>
            <img
              src={settingsIcon}

              className="cursor-pointer"
              alt="Notification settings  "
            />
          </Link>
          <Link
            to="notification"
            onClick={() => handleClose()}
            className={isOpen ? "nlink-secondary" : "d-none"}
            aria-hidden={isOpen ? false : true}
          >
            <span className="viewbutton" onClick={() => setRedirect(true)}>
              View all
            </span>
          </Link>
        </div>
      </div>
      {notifications.length > 0 ? (
        map(notifications, (item, index) => {
          const { icon, redirectURL } = getNotificationDetailsByType(item);
          return (
            <div className="notification-dialog-info-panel-custom">
              <InfoPanel
                className="custom-info-panel"
                notiPopUp={true}
                marginTop="0px"
                marginBottom="20px"
                displayStar={false}
                avatar={icon}
                avatarBackground={true}
                avatarHeight="50px"
                avatarWidth="50px"
                title={isEmpty(item.message) || isNull(item.message) ? item.title : item.message}
                subTitle={[moment(item.created_at).fromNow()]}
                isCardClick={true}
                onClick={() => {
                  if (redirectURL) {
                    props.history.push(redirectURL);
                    handleClose();
                  }
                }}
              />
            </div>
          );
        })
      ) : (
        <div className="no-notifications">
          <img src={NoNotifications} alt="No notifications" />
          <p className="header-primary">You’re all caught up!</p>
        </div>
      )}
    </div>
  </NotificationDialogStyles>
  );
};

export default NotificationDialog;
