import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { bindActionCreators } from "redux";

import { ActCreators } from "../../redux/bindActionCreator";
import { INTERSTRIDE_LOGO_WHITE } from "../../helper/utils/constants";

const SideBar = () => {
  let role = "";
  if (localStorage.getItem("role")) {
    role = localStorage.getItem("role");
  }

  return (
    <>
      <div className={`nav-sidebar`}>
        <div className="nav-header">
          <a href="javscript:void(0)">
            <img src={INTERSTRIDE_LOGO_WHITE} alt="Logo" />
          </a>
        </div>
        <div className={`navmenu`}>
          <div className="right-menu">
            <h3>EMPLOYER PORTAL</h3>
            <ul>
              {role === "admin" && (
                <li className="">
                  <Tooltip title="Employers" placement="right">
                    <Link
                      to="/employers"
                      className={
                        window.location.pathname.indexOf("/employer") === 0
                          ? "active"
                          : ""
                      }
                    >
                      Employers
                    </Link>
                  </Tooltip>
                </li>
              )}

              {(role === "admin" || role === "employer") && (
                <li className="">
                  <Tooltip title="Users" placement="right">
                    <Link
                      to="/users"
                      className={
                        window.location.pathname.indexOf("/user") === 0
                          ? "active"
                          : ""
                      }
                    >
                      Users
                    </Link>
                  </Tooltip>
                </li>
              )}
              <li className="">
                <Tooltip title="Jobs" placement="right">
                  <Link
                    to="/jobs"
                    className={
                      window.location.pathname.indexOf("/job") === 0
                        ? "active"
                        : ""
                    }
                  >
                    Jobs
                  </Link>
                </Tooltip>
              </li>
              <li className="">
                <Tooltip title="Applicants" placement="right">
                  <Link
                    to="/applicants"
                    className={
                      window.location.pathname.indexOf("/applicant") === 0
                        ? "active"
                        : ""
                    }
                  >
                    Applicants
                  </Link>
                </Tooltip>
              </li>
            </ul>

            <h3 className="divider">Resources</h3>
            <ul>
              <li className="">
                <Tooltip title="Hiring Tutorial" placement="right">
                  <Link
                    to="/hiring-tutorial"
                    className={
                      window.location.pathname.indexOf("/hiring-tutorial") === 0
                        ? "active"
                        : ""
                    }
                  >
                    Hiring Tutorial
                  </Link>
                </Tooltip>
              </li>
              <li className="">
                <Tooltip title="Hiring Resources" placement="right">
                  <Link
                    to="/hiring-resource"
                    className={
                      window.location.pathname.indexOf("/hiring-resource") === 0
                        ? "active"
                        : ""
                    }
                  >
                    Hiring Resources
                  </Link>
                </Tooltip>
              </li>
              <li className="">
                <Tooltip title="Marketing" placement="right">
                  <Link
                    to="/marketing"
                    className={
                      window.location.pathname.indexOf("/marketing") === 0
                        ? "active"
                        : ""
                    }
                  >
                    Marketing
                  </Link>
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalState: { ...state.loginReducer },
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
