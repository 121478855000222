import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ActCreators } from "../../../redux/bindActionCreator";
import CourseIframe from "./courseIframe";

const HiringTutorial = (props) => {
  const [activeTab, changeTab] = useState("employer_guide");
  const coursesList = [
    {
      id: "employer_guide",
      name: "Employer Guide",
      url: "https://learn.interstride.com/employer_guide",
    },
  ];

  return (
    <>
      <div className="page-header">
        <div className="left">
          <h3>Hiring Tutorial</h3>
        </div>
      </div>
      <CourseIframe coursesList={coursesList} activeCourse={activeTab} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalState: { ...state.loginReducer },
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(HiringTutorial);
