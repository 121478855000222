import React from "react";
import { TextField } from "@material-ui/core";

//import TextboxStyles from "./TextboxStyles";

export default function Textbox({
  onClick = (e) => {},
  onChange = (e) => {},
  onBlur = (e) => {},
  name,
  value,
  type = "text",
  placeholder,
  label = "",
  isMultiline = false,
  rows = 3,
  error = false,
  errorText = "",
  required = false,
  readonly=false,
  ...props
}) {
  const maxLength = props.maxLength
    ? props.maxLength
    : isMultiline
    ? 1000
    : type === "password"
    ? 30
    : 120;

  const handleKeyPress = (event) => {
    if (props.onEnter) {
      if (event.key === "Enter") {
        props.onEnter(event);
      }
    }

    if (props.onKeyPress) {
      props.onKeyPress(event);
    }
  };
  
  return (
    //<TextboxStyles>
    <TextField
      // label={label}
      name={name}
      id={name}
      type={type}
      value={value}
      variant="outlined"
      placeholder={placeholder ? placeholder : label}
      onClick={(e) => onClick(e)}
      onChange={(e) => onChange(e)}
      onBlur={(e) => onBlur(e)}
      onKeyPress={(e) => handleKeyPress(e)}
      multiline={isMultiline}
      rows={isMultiline ? rows : 0}
      error={error}
      // error
      helperText={error && errorText ? errorText : ""}
      InputProps={{
        maxLength,
        readOnly: readonly,
      }}
      // helperText="Error?"
      {...props}
    />
    //</TextboxStyles>
  );
}
