export const UNITED_STATES = 236;

export const visaList = [
  {
    value: "Candidates on CPT / OPT may apply",
    label: "Candidates on CPT / OPT may apply",
  },
  {
    value: "H-1B visa sponsorship offered",
    label: "H-1B visa sponsorship offered",
  },
  {
    value: "Visa sponsorship not offered",
    label: "Visa sponsorship not offered",
  },
  {
    value: "U.S. Permanent Residents and Citizens only",
    label: "U.S. Permanent Residents and Citizens only",
  },
];

export const workAuthorizationUSInternship = [
  {
    value: "CPT accepted",
    label: "CPT accepted",
  },
  {
    value: "OPT accepted",
    label: "OPT accepted",
  },
  {
    value: "OPT STEM accepted",
    label: "OPT STEM accepted",
  },
  {
    value: "Other (Please specify)",
    label: "Other (Please specify)",
  },
];

export const workAuthorizationUSFulltime = [
  {
    value: "OPT accepted",
    label: "OPT accepted",
  },
  {
    value: "STEM OPT accepted only",
    label: "STEM OPT accepted only",
  },
  {
    value: "H-1B sponsorship may be offered",
    label: "H-1B sponsorship may be offered",
  },
  {
    value: "H-1B sponsorship is offered",
    label: "H-1B sponsorship is offered",
  },
  {
    value: "Green card sponsorship may be offered",
    label: "Green card sponsorship may be offered",
  },
  {
    value: "Green card sponsorship is offered",
    label: "Green card sponsorship is offered",
  },
  {
    value: "Other (Please specify)",
    label: "Other (Please specify)",
  },
];

export const workAuthorizationNonUS = [
  {
    value: "Company to provide work authorization",
    label: "Company to provide work authorization",
  },
  {
    value: "Individual to provide work authorization",
    label: "Individual to provide work authorization",
  },
  {
    value: "No work authorization needed",
    label: "No work authorization needed",
  },
  {
    value: "Other (Please specify)",
    label: "Other (Please specify)",
  },
];

export const jobTypeList = [
  {
    value: "Full-time job",
    label: "Full-time",
  },
  {
    value: "Internship",
    label: "Internship",
  },
];

export const statusList = [
  {
    value: 1,
    name: "New",
  },
  {
    value: 2,
    name: "Screened",
  },
  {
    value: 3,
    name: "Interviewed",
  },
  {
    value: 4,
    name: "Offer made",
  },
  {
    value: 5,
    name: "Hired",
  },
  {
    value: 6,
    name: "Rejected",
  },
];

export const statusL = {
  New: [
    { value: 2, name: "Screened" },
    { value: 3, name: "Interviewed" },
    { value: 4, name: "Offer made" },
    { value: 5, name: "Hired" },
    { value: 6, name: "Rejected" },
  ],
  Screened: [
    { value: 3, name: "Interviewed" },
    { value: 4, name: "Offer made" },
    { value: 5, name: "Hired" },
    { value: 6, name: "Rejected" },
  ],
  Interviewed: [
    { value: 4, name: "Offer made" },
    { value: 5, name: "Hired" },
    { value: 6, name: "Rejected" },
  ],
  "Offer made": [
    { value: 5, name: "Hired" },
    { value: 6, name: "Rejected" },
  ],
  Hired: [{ value: 6, name: "Rejected" }],
  Rejected: [{ value: 5, name: "Hired" }],
};

export const ServicesTypes = {
  HIRING_RESOURCES: "hiring_resources",
  MARKETING: "marketing",
};

export const INTERSTRIDE_LOGO_WHITE =
  "https://interstride.s3.amazonaws.com/branding/brand-header-default-logo-inverse.svg";

export const INTERSTRIDE_DEFAULT_LOGO =
  "https://interstride.s3.amazonaws.com/branding/brand-header-default-logo.svg";
