import styled from "styled-components";
let PRIMARY_COLOR = "#000485";

export default styled.div.attrs({ className: "" })`
  width: 100%;
  .info-panel-card {
    padding: 23px 40px 23px 25px;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 103px;

    &.panel-active {
      background: ${PRIMARY_COLOR};
      color: #fff;
      .card-content {
        .panel-header {
          .panel-title,
          .panel-sub-title {
            color: #fff;
          }
          .panel-title {
            .tags {
              .tag-item {
                color: ${PRIMARY_COLOR};
              }
            }
          }
          .panel-intro {
            color: var(--medium-gray-color);
            display: flex;
            align-items: center;
            .description {
              color: #fff;
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              width: 100%;
              max-width: 270px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .card-content {
      padding: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;

      .MuiAvatar-root {
        width: 50px;
        height: 50px;
        ${(props) => props.avatarBackground && "background: #F1F4F7"};
        img {
          width: ${(props) => props.avatarWidth};
          height: ${(props) => props.avatarHeight};
        }
      }
      .panel-header {
        margin-left: 10px;
        width: calc(100% - 292px);
        padding-right: 10px;
        .panel-title {
          color: ${PRIMARY_COLOR};
          margin-bottom: 3px;
          display: flex;
          align-items: center;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;

          .star {
            margin: -5px 0 0 7px;
          }

          .tags {
            display: flex;
            align-items: center;
            margin: -3px 0px 0px 7px;
            .tag-item {
              background: #e5eaf7;
              height: 20px;
              display: flex;
              align-items: center;
              padding: 3px 5px 0 5px;
              box-sizing: border-box;
              text-transform: capitalize;
              font-family: "TTCommons-Regular";
              border-radius: 10.5px;
              background-color: #e5eaf7;
              min-width: 60px;
              font-size: 15px;
              letter-spacing: 0;
              line-height: 15px;
              text-align: center;
              justify-content: center;
              margin: 0;
            }
          }
        }
        .panel-sub-title {
          font-size: 15px;
          color: ${PRIMARY_COLOR};
          line-height: 17px;
          margin-bottom: 4px;
          font-family: "TTCommons-Regular";
        }
        .panel-intro {
          color: var(--gray-text-color);
          font-size: 15px;
          line-height: 15px;
          display: flex;
          align-items: center;
          font-family: "TTCommons-Regular";
          .description {
            color: ${PRIMARY_COLOR};
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            width: 100%;
            max-width: 270px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .c-flag {
            height: 15px;
            width: 22px;
            border-radius: 2px;
            background-color: #d8d8d8;
            display: flex;
            align-items: center;
            overflow: hidden;
            margin: -2px 0 0 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }

      .panel-actions {
        margin-left: auto;
        max-width: 232px;
        display: flex;
        flex-wrap: wrap;
        button {
          margin: 0;
          padding: 4px 9px 2px 9px;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          text-align: center;
          height: auto;
          min-width: 101px;

          + button {
            margin-left: 30px;
          }
          &.btn-unfollow {
            background: var(--gray-text-color);
            color: #fff;
          }
        }
      }
    }
    .customEllipsis {
      color: #000485;
      margin: 0 -150px 0 0;
      max-width: 408px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .header-custom-width {
    width: 65% !important;
  }
`;
