import React, { Component } from "react";

class KommunicateChat extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    (function (d, m) {
      var defaultSettings = {
        defaultBotIds: ["employer-support-fwj0h"],
        defaultAssignee: "employer-support-fwj0h",
        skipRouting: true,
        //oneTimeRating: true,
      };
      var kommunicateSettings = {
        appId: "2eb6f76653610343aeff8ccc0fce7ff79",
        popupWidget: !true,
        // disableFormPostSubmit: true,
      };
      kommunicateSettings.onInit = function () {
        var css = `
        .mck-box-title {
          font-size: 16px;
        }
        .mck-tab-title {
          font-size: 16px !important;
        }
         
        .km-header-container
          .km-conversation-header-title-container
          .mck-row
          .mck-back-btn-container {
          width: 30px !important;
        }
        .km-header-container
          .km-conversation-header-title-container
          .mck-row
          .mck-back-btn-container
          .mck-conversation-tab-link {
          width: 25px !important;
        }
        .mck-actionable-form input {
          border: 2px solid #d1dde5 !important;
          border-radius: 45px !important;
          color: #000485;
          font-size: 14px !important;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          background-color: #fafbfd;
        }
        .mck-actionable-form textarea {
          border: 2px solid #d1dde5 !important;
          border-radius: 10px !important;
          color: #000485;
          font-size: 14px !important;
          font-weight: 500;
          letter-spacing: 0;
          background-color: #fafbfd;
        }
        .mck-actionable-form textarea:focus {
          border: 2px solid #d1dde5 !important;
        }
        input:disabled{
          background: transparent !important;
        }
        textarea:disabled{
          background: transparent !important;
        }
        .mck-actionable-form input:focus {
          border: 2px solid #d1dde5 !important;
        }
        .mck-form-submit-button {
          margin-top: 12px;
          background-color: #000485 !important;
          color: #fff !important;
          padding: 8px;
          width: 101px;
        }
        label{
          color: #000485 !important;
        }
        .mck-form-template-wrapper{
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          background-color: #fff;
          border-radius: 14px !important;
          width: 100%;
        }
        #km-faq{
          display: none;
        }
        `;
        try {
          window.kommunicate.updateSettings(defaultSettings);
          window.kommunicate.customizeWidgetCss(css);
        } catch (e) {
          console.log(e?.message);
        }
      };
      //kommunicateSettings.restartConversationByUser = true;
      kommunicateSettings.disableFormPostSubmit = true;
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
      var h = document.getElementsByTagName("head")[0];
      h.appendChild(s);
      window.kommunicate = m;
      m._globals = kommunicateSettings;
    })(document, window.kommunicate || {});
  }
  render() {
    return <div></div>;
  }
}
export default KommunicateChat;
