module.exports = {
  defaultValue: () => {
    if (/^localhost(:\d+)?$/.test(window.location.host)) {
      //Development
      return "https://stageemployer.interstride.com/";
    } else if (process.env.REACT_APP_ENV === "prod") {
      //Production
      return "https://employer.interstride.com/api/";
    } else {
      //Staging or QA
      return "https://stageemployer.interstride.com/";
    }
  },
  bugsnagAPIKey: () => {
    // Production;

    // return "d025d39cb6ab895606c5b37343334ca9";
    // Staging
    return "fa41045be8e56a8cd9aaf1084cb990ab";
  },
  commonAPI: () => {
    if (/^localhost(:\d+)?$/.test(window.location.host))
      return "https://web.testing.interstride.com/api/v1/";
    return "https://web.interstride.com/api/v1/";
  },
  googleTagManagerID: () => {
    return "GTM-MKZDSCV";
  },
  tinyMceAPIKey: () => {
    return "qw9zwfzk9lk0vxsfk2c11hh7dto8etqd25tq951bhzkx6z0r";
  },
};
