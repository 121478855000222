import React from "react";
import { useDropzone } from "react-dropzone";

import FileUploadStyles from "./FileUploadStyles";
import Uploading from "../../assets/svg/Upload.svg";
import pdfImage from "../../assets/img/pdfIcon.svg";
import docImage from "../../assets/img/docs.png";
import excelImage from "../../assets/img/excel.png";
import { isEmpty, isNull } from "underscore";

export default function FileUpload({
  handleUploadFile = (e) => {},
  text,
  vallidFiles,
  icon,
  imagePreview = false,
  docPreview = false,
  type,
  file = null,
  error = false,
  errorText,
}) {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    noDrag: true,
    onDrop: handleUploadFile,
  });

  // useEffect(() => {
  //   handleUploadFile(acceptedFiles);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [acceptedFiles]);

  // const files = acceptedFiles.map((f) => <li key={f.name}>{f.name}</li>);

  // const getBase64 = (file, cb) => {
  //   setType(file.type);
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //     setImage(reader.result)
  //   };
  //   reader.onerror = function (error) {
  //     console.log('Error: ', error);
  //   };

  //   return reader.result
  // }
  const imageType = ["image/jpg", "image/jpeg", "image/png"];
  const videoType = ["video/mp4", "video/quicktime"];
  const pdfType = ["application/pdf"];
  const excelType = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const docType = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  return (
    <FileUploadStyles>
      <div className={`fileupload-custom ${error ? "error" : ""}`}>
        <div
          className="dropzone-custom"
          {...getRootProps({
            className: "dropzone cursor-pointer",
          })}
          onClick={open}
        >
          <input
            {...getInputProps()}
            accept={vallidFiles}
            //onChange={(e) => getBase64(e.target.files[0])}
          />

          {imageType.includes(type) || imagePreview ? (
            <div className={`upload-img uploaded ${icon ? "preview" : "placeholder"}`}>
              <img src={icon && !isNull(icon) && !isEmpty(icon) ? icon : Uploading} />
            </div>
          ) : videoType.includes(type) ? (
            <video src={icon} width="320" height="240" controls />
          ) : pdfType.includes(type) ? (
            <img src={pdfImage} width="352" height="242" />
          ) : docType.includes(type) ? (
            <img src={docImage} width="352" height="242" />
          ) : excelType.includes(type) ? (
            <img src={excelImage} width="352" height="242" />
          ) : (
            <>
              {docPreview && icon && icon !== null ? (
                <div className="file-name-preview"> {icon}</div>
              ) : (
                <div>
                  <img className="other-type-preview" src={Uploading} />
                </div>
              )}
            </>
          )}
        </div>
        {!imageType.includes(type) && !videoType.includes(type) && file && file !== null ? (
          <>
            <div className="file-name-preview">
              {" "}
              <a href="/#">{file.name}</a>
            </div>
            <br />
          </>
        ) : (
          ""
        )}
        <div className="cursor-pointer"> {text}</div>

        {error && errorText && <div className="error-text"> {errorText}</div>}
      </div>
    </FileUploadStyles>
  );
}
