import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .MuiCard-root {
    border-radius: ${(props) => props.borderRadius}px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    // overflow: initial;
  }
`;
