export const AddNotificationsettings = {
  url: "employer/employer_notification_settings/add",
  method: "POST",
};

export const GetNotificationsettings = {
  url: "employer/employer_notification_settings",
  method: "GET",
};

export const GetNotificationList = {
  url: "employer/notifications",
  method: "GET",
};

export const GetUnreadNotificationCount = {
  url: "employer/notifications/notification_count",
  method: "GET",
};

export const RemoveNotification = {
  url: "employer/notifications/remove_notification",
  method: "GET",
};

export const ReadAllNotification = {
  url: "employer/notifications/read_all_notification",
  method: "GET",
};
