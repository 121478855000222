import React, { useRef, useEffect } from "react";

const CourseIframe = ({ coursesList, activeCourse }) => {
  const ref = useRef(null);

  useEffect(() => {
    let iframe = document.getElementById("iframeTarget");
    let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    if (iframeWin.document.body)
      iframe.width =
        window.screen.width > 985
          ? window.screen.width - 90
          : window.screen.width - 30;
  }, []);

  return (
    <iframe
      src={
        coursesList.find((course) => course.id == activeCourse)?.url ||
        "https://learn.interstride.com/immigration/"
      }
      style={{ border: 0, width: "100%", height: "calc(100vh - 200px)" }}
      id="iframeTarget"
      ref={ref}
    />
  );
};
export default CourseIframe;
