import { REHYDRATE } from "redux-persist";

let initState = {
  user: {},
  isAdmin: false,
  isEmployer: false,
  isUser: false,
  isLogin: false,
  selectedCompany: {},
  refreshCompanyCount: 0,
  NotificationsCount: 0,
};

const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return {
        ...state,
        user: action.res,
        isAdmin: action.res.role === "admin",
        isEmployer: action.res.role === "employer",
        isUser: action.res.role === "hr",
        isLogin: true,
      };

    case "UPDATE_USER_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.res,
          authentication_token: state.user.token,
        },
      };

    case "RESET_USER_DATA":
      return {
        ...initState,
      };

    case "UPDATE_SELECTED_COMPANY":
      return {
        ...state,
        selectedCompany: action.res,
      };

    case "REFRESH_COMPANY_LIST":
      return {
        ...state,
        refreshCompanyCount: state.refreshCompanyCount + 1,
      };

    case "NOTIFICATION_COUNT":
      return {
        ...state,
        NotificationsCount: action.res,
      };

    case REHYDRATE:
      return {
        ...state,
        ...(action.payload && action.payload.loginReducer
          ? action.payload.loginReducer
          : initState),
      };
    default:
      return {
        ...state,
      };
  }
};
export default loginReducer;
