import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
.notification-overlay {
    position: fixed;
    left: 100%;
    top: 58px;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 148, 171, 0.2);
    transition: all 0.1s;
    -webkit-transition: all 0.1s !important;
    z-index: 1050;
    &.notification-overlay-open {
      left: 0;
    }
  }
  .notifications-drawer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 56px;
    right: -595px;
    padding: 24px 30px;
    max-width: 595px;
    background: #ffffff;
    transition: all 0.5s;
    -webkit-transition: all 0.5s !important;
    border: 2px solid #ebf1f7;
    background-color: #fafbfd;
    z-index: 99;
    overflow-y: auto;
    max-height: calc(100vh - 58px);
    z-index: 1051;
    @media (max-width: 1024px) {
      padding: 16px 16px;
    }
    @media (max-width: 600px) {
      width: 92%;
    }
    &.notifications-drawer-open {
      right: 0px;
    }
    .no-notifications {
      display: flex;
      align-items: center;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      padding-top: 0;
      word-break: break-word;
      margin: 0;
      img {
        margin-right: 15px;
        margin-top: -2px;
      }
    }
    .notification-dialog-info-panel-custom {
      .header-custom-width {
        width: 100% !important;
      }
      .info-panel {
        .info-panel-card {
          margin-bottom: 10px;
          .card-content {
            .MuiAvatar-root {
              width: 50px;
              height: 50px;
              min-width: 50px;
              img {
                width: 50px;
                height: 50px;
              }
            }
            .panel-title {
              @media (max-width: 767px) {
                font-size: 16px;
                line-height: 16px;
              }
            }
            .panel-sub-title {
              color: var(--field-label-color);
              margin-bottom: 0;
            }
            .panel-actions {
              opacity: 1;
              visibility: visible;
              align-items: center;
              margin-left: auto !important;
              .shadow-btn {
                background: #fff !important;
                box-shadow: var(--card-box-shadow) !important;
                color: var(--primary-dark-color);
                width: auto;
                padding: 10px 8px 6px;
                height: auto;
                height: 35px;
                min-height: 35px;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
                font-family: "TTCommons-Medium";
                border-radius: 100px;
                text-decoration: none;
                transition: all 0.3s;
                display: inline-block;
                text-transform: initial;
                &:hover {
                  box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
                }
              }
              .arrow-btn {
                width: 24px;
                height: 24px;
                min-width: auto;
                min-height: auto;
                background: transparent !important;
                padding: 0 !important;
                .content {
                  min-width: auto;
                  display: flex;
                  img {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  .notification-dialog-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .notification-dialog-title-view-all-wrapper {
      display: flex;
      flex-direction: row;
      .header-primary {
        font-size: 24px;
      }
      button {
        margin: 0;
      }
    }
    .notification-dialog-setting-close-icon-wrapper {
      display: flex;
      align-items: center;
      .viewbutton {
        font-family: TTCommons-Medium;
        font-size: 18px;
        padding: 7px 8px 3px;
        box-shadow: none !important;
        overflow: hidden;
        display: inline-block;
        text-decoration: none;
        text-align: center;
        border-radius: 100px;
        min-width: 100px;
        min-height: 30px;
        line-height: normal;
        vertical-align: middle;
        background-color: #0030b5;
        border-color: #0030b5;
        color: #fff;
        text-transform: none;
      }
      .notification-dialogue-setting-icon-wrapper {
        svg {
          fill: var(--primary-color) !important;
          margin-right: 0.5rem;
          cursor: pointer;
        }
        &:focus {
          svg {
            box-shadow: 0 0 8px 4px #000c3d;
          }
        }
      }
    }
  }
  
  .notification-dialog-info-panel-custom {
    div > div > div > div > div {
      flex-wrap: nowrap !important;
    }
    img {
      object-fit: contain !important;
    }
  }
  
  .notification-dialog-see-all {
    display: flex;
    justify-content: center;
  }
  
  .no-notification-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    img {
      width: 800px;
      margin-top: -75px;
      margin-bottom: 4rem;
    }
    span {
      margin-left: 20px;
      color: var(--primary-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  
  .notification-settings-group-list {
    &-card {
      margin-bottom: 20px;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      overflow: hidden;
      padding: 31px 50px 29px 25px;
    }
    &-content {
      h3 {
        color: var(--primary-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
      }
    }
    &-options {
      margin-top: 5px;
      &-item {
        display: flex;
        align-items: center;
        input {
          margin: 0;
        }
        &-description {
          margin-top: 3px;
          margin-left: 0.5rem;
          font-family: "TTCommons-Regular";
          font-size: 18px;
          line-height: 22px;
          color: var(--gray-text-color);
        }
      }
    }
  }`;


