import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import config from "./config";

try {
  if (process.env.REACT_APP_ENV === "prod") console.log = () => {};
} catch (error) {
  //ignore it atm
  console.error(error);
}

Bugsnag.start({
  apiKey: config.bugsnagAPIKey(),
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging"],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
