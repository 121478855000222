import styled from "styled-components";
// import { PRIMARY_COLOR, GRAY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .modal-backdrop {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;
