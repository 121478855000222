import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Container } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import Bugsnag from "@bugsnag/js";
import { Transition } from "react-transition-group";

import { ActCreators } from "../../redux/bindActionCreator";
import Textbox from "../../components/Textbox/Textbox";
import Checkbox from "../../components/Checkbox/Checkbox";
import ModalBackdrop from "../../components/Backdrop/Backdrop";
import Signup from "../Signup/Signup";
import TransitionHelper from "./TransitionHelper";
import { EmployerLogin } from "../../common/module_wise_api_url/onboarding";
import { commonApiFN } from "../../common/api";
import {
  toastify,
  validateString,
  validateEmail,
} from "../../helper/utils/validation";
import "./LoginStyles.scss";
import CheckIcon from "../../assets/img/e-portal-instruction-check-icon.svg";
import { INTERSTRIDE_DEFAULT_LOGO } from "../../helper/utils/constants";

const Login = (props) => {
  const formRef = useRef();

  useEffect(() => {
    if (localStorage.getItem("isLogin")) {
      let role = localStorage.getItem("role");

      if (role === "admin") props.history.push("/employers");
      else if (role === "employer") props.history.push("/users");
      else props.history.push("/jobs");
    }
  }, []);

  const login = async (values) => {
    const loginData = EmployerLogin;

    loginData.data = {
      login: {
        email: values.email,
        password: values.password,
      },
    };

    const response = await commonApiFN(loginData);

    if (response.success) {
      const users = response.data;

      if (users.user) {
        sessionStorage.setItem("isLogin", "1");
        localStorage.setItem("isLogin", "1");
        localStorage.setItem("token", users.user.token);
        localStorage.setItem("role", users.user.role);

        Bugsnag.setUser(
          users.user.id,
          users.user.email,
          users.user.firstname + " " + users.user.lastname
        );

        if (users.user.role === "admin") {
          props.history.push("/employers");
        } else if (users.user.role === "employer") {
          props.history.push("/users");
          localStorage.setItem("employer_id", users.user.employer_id);
        } else {
          props.history.push("/jobs");
          localStorage.setItem("employer_id", users.user.employer_id);
        }
        props.setUserData(users.user);
      }
    } else {
      toastify("error", response.message);
    }
  };
  const [isOpen, toggleIsOpen] = useState(false);
  const duration = 500;

  const instructions = [
    " Connect with 80,000+ international students and alumni",
    "Hire from hundreds of top U.S. universities and colleges",
    "Build a diversified team with talent from over 150 countries",
    "Get support and perks by joining our employer portal",
  ];

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [isOpen]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    isOpen && toggleIsOpen(false);
  };
  return (
    <>
      <>
        <Transition
          in={isOpen}
          timeout={duration}
          unmountOnExit
          appear
          exit={false}
        >
          {(state) => (
            <ModalBackdrop duration={duration} state={state}>
              <ToastContainer />
              {isOpen && (
                <TransitionHelper duration={duration} state={state}>
                  <Signup
                    onLoginButtonClick={() => toggleIsOpen(false)}
                    transition={true}
                  />
                </TransitionHelper>
              )}
            </ModalBackdrop>
          )}
        </Transition>
      </>
      <div className="e-login-container">
        <div className="e-login-container__item">
          <div className="e-instructions">
            <div className="e-instructions__container">
              <div className="heading">
                Post your first job or internship within minutes.
              </div>
              <div className="subtitle">No hidden costs. 100% free.</div>
              <div className="list-items">
                {instructions.map((instruction, index) => (
                  <div className="list-items__item" key={index}>
                    <div className="icon">
                      <img src={CheckIcon} alt="Check icon" />
                    </div>
                    <div className="text">{instruction}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="e-login-container__item">
          <div className="e-login-form">
            <div className="e-login-form__logo">
              <div className="brand-wrapper">
                <div className="logo">
                  <img
                    src={INTERSTRIDE_DEFAULT_LOGO}
                    alt={"Interstride Logo"}
                    height={"35px"}
                  />
                </div>
              </div>
            </div>
            <div className="e-login-form__heading">
              Welcome to the employer portal
            </div>

            <div className="e-login-form__item">
              <Container component="main" maxWidth="xs">
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validate={(values) => {
                    const errors = {};

                    if (validateString(values.email)) {
                      errors.email = "Please enter email";
                    } else if (validateEmail(values.email)) {
                      errors.email = "Please enter valid email";
                    }

                    if (validateString(values.password)) {
                      errors.password = "Please enter password";
                    }

                    return errors;
                  }}
                  onSubmit={(values) => {
                    login(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      className="e-form"
                      name="form"
                      onSubmit={handleSubmit}
                      style={{ marginBottom: "13px" }}
                    >
                      <div className="form-group">
                        <Textbox
                          variant="outlined"
                          fullWidth
                          id="email"
                          placeholder="Enter your email address"
                          name="email"
                          autoComplete="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={errors.email && touched.email}
                          errorText={errors.email}
                        />
                      </div>

                      <div className="form-group">
                        <Textbox
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="password"
                          placeholder="Enter your password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          error={errors.password && touched.password}
                          errorText={errors.password}
                        />
                      </div>
                      <div className="form-group form-group__keep-signedin">
                        <Checkbox />
                        <span className="label">
                          Keep me signed in on this device
                        </span>
                      </div>

                      <div className="form-group form-group__login-button">
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="btn btn-negative"
                          style={{ textTransform: "none" }}
                        >
                          Log in
                        </Button>
                      </div>
                      <p className="e-form__forgot-password text-center">
                        <Link to="/forget-password">Forgot password ?</Link>{" "}
                      </p>

                      <p className="e-form__subtitle">New to Interstride?</p>

                      <div className="form-group form-group__signup-btn">
                        <Button
                          type="button"
                          fullWidth
                          color="primary"
                          className="btn btn-active"
                          style={{ textTransform: "none" }}
                          onClick={() => toggleIsOpen(!isOpen)}
                        >
                          Sign up!
                        </Button>
                      </div>
                      <div className="spacer"></div>
                    </form>
                  )}
                </Formik>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    globalState: { ...state.loginReducer },
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
