import React from "react";
import { Button, CssBaseline, Container } from "@material-ui/core";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { clone } from "underscore";

import TextBox from "../../components/Textbox/Textbox";
import { ForgotPasswordApi } from "../../common/module_wise_api_url/onboarding";
import { commonApiFN } from "../../common/api";
import { toastify } from "../../helper/utils/validation";
import { INTERSTRIDE_LOGO_WHITE } from "../../helper/utils/constants";
import BackArrow from "../../assets/svg/back-arrow.svg";

const ForgotPassword = (props) => {
  const handleForgotPassword = async (values) => {
    let requestData = {};

    requestData = clone(ForgotPasswordApi);
    requestData.data = {
      employer_profile: {
        email: values,
        frontend_url: window.location.origin,
      },
    };

    const response = await commonApiFN(requestData);

    if (response.success) {
      toastify("success", response.message);
      props.history.push("/");
    } else {
      toastify("error", response.message);
    }
  };

  return (
    <section
      className="OnboardingWrapper EmployersWrapper ForgotpwdWrapper"
      style={{ minHeight: "100vh" }}
    >
      <CssBaseline />
      <div className="BrandLogo text-center mb-30">
        <a href="/">
          <img src={INTERSTRIDE_LOGO_WHITE} style={{ height: 36 }} alt="Logo" />
        </a>
      </div>

      <div className="back-url">
        <Link to="/" className="link-focus">
          <img
            src={BackArrow}
            id="BackArrow"
            className="back-link"
            width="18px"
            alt="Back arrow"
          />
        </Link>
      </div>
      <h1 className="text-center mb-30">Woops!</h1>
      <h1 className="text-center mb-30">Forgot your password?</h1>
      <h2 className="text-center mb-30">
        Enter your email below and we’ll send you a link to reset your password.
      </h2>
      <Container component="main" maxWidth="xs">
        <Formik
          initialValues={{ email: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Please enter email";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Please enter valid email";
            }
            return errors;
          }}
          onSubmit={(values, actions) => {
            handleForgotPassword(values.email);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              className="form"
              name="form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !errors.email) {
                  handleSubmit();
                }
              }}
            >
              <div className="form-group">
                <TextBox
                  variant="outlined"
                  fullWidth
                  id="email"
                  placeholder="Enter your email"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email && touched.email}
                  errorText={errors.email}
                />
              </div>

              <div className="">
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="btn btn-negative"
                  style={{ textTransform: "none" }}
                  isSubmitting={isSubmitting}
                  onClick={(e) => {
                    if (!errors.email) {
                      handleSubmit(e);
                    }
                  }}
                >
                  Reset password
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Container>
      <div
        className="text-center"
        style={{ color: "#fff", marginTop: "-70px" }}
      >
        If you don’t see the email, please check your spam folder. Still having
        problems? Email us at &nbsp;
        <a
          href={`mailto:contact@interstride.com`}
          className="link-focus"
          style={{ color: "#E5190B", borderColor: "#E5190B" }}
        >
          contact@interstride.com
        </a>
      </div>
    </section>
  );
};

export default ForgotPassword;
