import React from "react";
import InfoPanelStyles from "./InfoPanelStyles";
import CardCustom from "../CardCustom/CardCustom";
import { Avatar, Tooltip } from "@material-ui/core";
//import cls from "classnames";
//import StarIcon from "../StarIcon/StarIcon";
import NoUserImage from "../../assets/svg/noUser.svg";
import InitialAvatar from "../InitialAvatar/InitialAvatar";

export const InfoPanel = ({
  index = null,
  avatar = null,
  userAvatar = false,
  notiPopUp = false,
  title,
  subTitle = [],
  intro,
  action,
  isActive = false,
  tags = [],
  isFavorites = false,
  avatarBackground = false,
  displayStar = true,
  avatarHeight = "100%",
  avatarWidth = "100%",
  marginTop = "30px",
  marginBottom = "0px",
  onClick = () => {},
  isCardClick = false,
  classname,
}) => {
  return (
    <InfoPanelStyles
      avatarHeight={avatarHeight}
      avatarWidth={avatarWidth}
      avatarBackground={avatarBackground}
    >
      <div className={`info-panel ${classname} ${isCardClick ? "cursor-pointer" : ""}`}>
        <CardCustom className="info-panel-card" onClick={isCardClick ? onClick : () => {}}>
          {avatar && avatar.length > 0 && avatar !== null ? (
            <Avatar alt="" src={avatar} />
          ) : (
            <>
              {userAvatar ? (
                <InitialAvatar title={title} index={index} />
              ) : (
                <Avatar alt="" src={NoUserImage} />
              )}
            </>
          )}
          <div className={`panel-header ${notiPopUp ? "header-custom-width" : ""}`}>
            <div className="panel-title">
              <Tooltip title={title} placement="bottom">
                <div className="customEllipsis">{title}</div>
              </Tooltip>
              {tags && tags.length > 0 && (
                <div className="tags">
                  {tags.map((item, i) => (
                    <div key={i} className="tag-item" style={{ marginLeft: "1px" }}>
                      {item}
                    </div>
                  ))}
                </div>
              )}

              {/* {displayStar &&
                (isFavorites ? (
                  <StarIcon
                    aria-label={`Remove ${title} from favourites`}
                    variant="filled"
                    onClick={onClick}
                  />
                ) : (
                  <StarIcon
                    aria-label={`Add ${title} to favourites`}
                    onClick={onClick}
                  />
                ))} */}
            </div>
            {/* <div className="panel-sub-title">{typeof subTitle === 'array' ? subTitle.join(" | ") : subTitle}</div> */}
            {subTitle && (
              <div className="panel-sub-title">
                {subTitle && typeof subTitle === "object" ? subTitle.join(" | ") : subTitle}
              </div>
            )}
            <div className="panel-intro">{intro}</div>
          </div>
          <div className="panel-actions">{action}</div>
        </CardCustom>
      </div>
    </InfoPanelStyles>
  );
};
