import React from "react";

const TransitionHelper = ({ children, duration, state }) => {
  const defaultStyle = {
    transition: `transform ${duration}ms ease-in-out`,
    transform: "translateX(101%)",
  };

  const transitionStyles = {
    entering: { transform: "translateX(101%)" },
    entered: { transform: "translateX(0)" },
  };
  return (
    <div
      className="e-form-transition-container"
      style={{
        ...defaultStyle,
        ...transitionStyles[state],
      }}
    >
      {children}
    </div>
  );
};

export default TransitionHelper;
