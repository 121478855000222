import React, { Suspense, useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import routes from "../../routes";
import SideBar from "../Common/sidebar";
import Header from "../Common/header";
import Loader from "../../components/Loader/Loader";
import KommunicateChat from "../../components/ChatBot/ChatBot";
import { ActCreators } from "../../redux/bindActionCreator";


const Layout = (props) => {
  const [showFormLoader, setShowFormLoader] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [disableActions, setDisableActions] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("isLogin")) {
      props.history.push("/");
    }
  }, []);

  const drawerOpenFn = (val) => {
    setIsDrawerOpen(val);
  };

  const changeDisableActions = (value) => {
    setDisableActions(value);
  };

  useEffect(() => {
    setStep(1);
    setDisableActions(false);
  }, [window.location.pathname]);

  return (
    <section className="main-content Sidebar-open">
      <SideBar drawerOpenFn={drawerOpenFn} />
      <Header history={props.history} />

      <div
        className={`page-content-wrap ${showFormLoader ? "form-loader" : ""}`}
      >
        <Suspense fallback={<Loader />}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <>
                        <div className="wizzard-content">
                          <route.component
                            step={step}
                            showFormLoader={showFormLoader}
                            setShowFormLoader={setShowFormLoader}
                            setDisableFormActions={changeDisableActions}
                            {...props}
                          />
                        </div>
                      </>
                    )}
                  />
                )
              );
            })}{" "}
            <Redirect from="/" to="/employers" />{" "}
          </Switch>{" "}
          <KommunicateChat />
        </Suspense>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    globalState: { ...state.loginReducer },
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
