import React from "react";
// import { Checkbox } from "@material-ui/core";

import CheckboxStyles from "./CheckboxStyles";
import Selected from "../../assets/svg/Selected-checkbox.svg";
import UnSelected from "../../assets/svg/uncheck-input.svg";
export default function CheckboxCustom({
  value,
  isDisabled = false,
  isChecked = false,
  onClick = (e) => {},
}) {
  return (
    <CheckboxStyles>
      {/* <Checkbox checked={value} onChange={onChange} disabled={isDisabled} /> */}
      <div className="custom-checkbox">
        {isChecked ? (
          <input type="checkbox" onClick={onClick} checked value={value} />
        ) : (
          <input type="checkbox" onClick={onClick} value={value} />
        )}

        <span className="checkmark">
          {isChecked ? (
            <img src={Selected} alt="checkbox" />
          ) : (
            <img src={UnSelected} alt="checkbox" />
          )}
        </span>
      </div>
    </CheckboxStyles>
  );
}
