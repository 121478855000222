//common function to call the api

import { store } from "../redux/store";
import _ from "underscore";
//import { toastify } from "../helper/helper";
import config from "../config";

const axios = require("axios");
const qs = require("qs");
//console.log("configdata",config.defaultValue());
const API_URL = config.defaultValue();
export const commonApiFN = (requestData) => {
  const BASE_URL = API_URL;

  const state = store.getState();
  //console.log("state",state.loginReducer.user.user.token);
  // const token = _.get(
  //   state,
  //   ["loginReducer", "user", "authentication_token"],
  //   null
  // );
  let token = "";
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  }

  let headers = {};
  if (token && token != null) {
    headers.Authorization = token;
  }
  //console.log("headers",headers);
  var promise = new Promise(function (resolve, reject) {
    axios({
      method: requestData.method,
      url: BASE_URL + requestData.url,
      data: requestData.data ? requestData.data : undefined,
      headers,
      params: requestData.params ? requestData.params : undefined,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    })
      .then(function (response) {
        if (
          response &&
          response.data &&
          response.data.success &&
          requestData.notify
        ) {
          //toastify("success", response.data.message);
        } else if (requestData.notify) {
          //toastify("error", response.data.message);
        }
        resolve(response.data);
      })
      .catch(function (error) {
        if (error && error.response && error.response.status === 401) {
          sessionStorage.clear();
          localStorage.clear();
          window.location.pathname = "/";
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.success == false &&
          requestData.notify
        ) {
          /*if (error.response.data.message)
            toastify("error", error.response.data.message);
          else toastify("error", error.response.statusText);*/
        }
        resolve(
          error && error.response && error.response.data
            ? error.response.data
            : {}
        );
      });
  });
  return promise;
};
