//Employer

export const AddEmployer = {
  url: "employer/add",
  method: "POST",
};

export const UpdateEmployer = {
  url: "employer/update",
  method: "PUT",
};

export const deleteEmployer = {
  url: "employer/delete/",
  method: "DELETE",
};

export const GetEmployerList = {
  url: "employer",
  method: "GET",
};

export const GetEmployerDataById = {
  url: "employer/show/",
  method: "GET",
};

export const acceptEmployer = {
  url: "employer/accept/",
  method: "POST",
};

export const denyEmployer = {
  url: "employer/deny/",
  method: "GET",
};

export const validateEmployer = {
  url: "employer/validate/",
  method: "POST",
};

export const validateUser = {
  url: "employer/user/validate/",
  method: "POST",
};
