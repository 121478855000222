import React from "react";
import HiringTutorial from "./pages/Services/HiringTutorial";

const Employers = React.lazy(() => import("./pages/Employers/EmployersList"));
const EmployerAdd = React.lazy(() => import("./pages/Employers/EmployerAdd"));
const EmployerEdit = React.lazy(() => import("./pages/Employers/EmployerEdit"));
const MyProfile = React.lazy(() => import("./pages/MyProfile/index"));
const Notification = React.lazy(() => import("./pages/Notification/index"));
const NotificationSettings = React.lazy(() =>
  import("./pages/Notification/NotificationManage")
);

const Users = React.lazy(() => import("./pages/Users/UsersList"));
const UserAdd = React.lazy(() => import("./pages/Users/UserAdd"));

const Jobs = React.lazy(() => import("./pages/Jobs/JobList"));
const JobAdd = React.lazy(() => import("./pages/Jobs/JobAdd"));

const Marketing = React.lazy(() => import("./pages/Services/Marketing"));
const MarketingAdd = React.lazy(() => import("./pages/Services/Marketing/Add"));
const MarketingEdit = React.lazy(() => import("./pages/Services/Marketing/Edit"));

const HiringResource = React.lazy(() => import("./pages/Services/HiringResource"));
const HrAdd = React.lazy(() => import("./pages/Services/HiringResource/Add"));
const HrEdit = React.lazy(() => import("./pages/Services/HiringResource/Edit"));

const JobsApplicants = React.lazy(() => import("./pages/Jobs/JobsApplicants"));
const Applicants = React.lazy(() => import("./pages/Applicants/Applicants"));
const HelpCenter = React.lazy(() => import("./pages/HelpCenter/HelpCenter"));

const routes = [
  {
    path: "/employers",
    name: "Employers",
    component: Employers,
  },
  {
    path: "/employer/add",
    name: "Employers",
    step: 1,
    component: EmployerAdd,
  },
  {
    path: "/employer/edit",
    name: "Employers",
    component: EmployerEdit,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/user/add",
    name: "Users",
    component: UserAdd,
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: Marketing,
  },
  {
    path: "/resource-marketing/edit",
    name: "Marketing",
    component: MarketingEdit,
  },
  {
    path: "/resource-marketing/add",
    name: "Marketing",
    component: MarketingAdd,
  },
  {
    path: "/hiring-resource",
    name: "HiringResource",
    component: HiringResource,
  },
  {
    path: "/hiring-tutorial",
    name: "HiringTutorial",
    component: HiringTutorial,
  },
  {
    path: "/resource-hr/add",
    name: "Marketing",
    component: HrAdd,
  },
  {
    path: "/resource-hr/edit",
    name: "Marketing",
    component: HrEdit,
  },
  {
    path: "/job/add",
    name: "Jobs",
    component: JobAdd,
  },
  {
    path: "/jobapplicants",
    name: "Jobs",
    component: JobsApplicants,
  },
  {
    path: "/applicants",
    name: "Applicants",
    component: Applicants,
  },
  {
    path: "/my-profile",
    name: "Profile",
    component: MyProfile,
  },
  {
    path: "/notification",
    name: "Notifications",
    component: Notification,
  },
  {
    path: "/notification-settings",
    name: "Notifications",
    component: NotificationSettings,
  },
  {
    path: "/help-center",
    name: "HelpCenter",
    component: HelpCenter,
  },
];

export default routes;
