import React from "react";
import LoaderStyles from "./LoaderStyles";

const Loader = ({ className }) => {
  return (
    <LoaderStyles>
      <div className="loader">
        <div className={`lds-ellipsis ${className ?? ""}`}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </LoaderStyles>
  );
};

export default Loader;
